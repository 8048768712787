<script lang="js">
import { camelCase } from 'lodash-es';

import {
  mdiAccount,
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiAccountMultiplePlus,
  mdiAccountSearch,
  mdiAlarmCheck,
  mdiArchiveOutline,
  mdiArrowDown,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowUp,
  mdiArrowUpDown,
  mdiBell,
  mdiBellOff,
  mdiBellOutline,
  mdiBellRingOutline,
  mdiBookOpenPageVariant,
  mdiCalendar,
  mdiCallSplit,
  mdiCamera,
  mdiCancel,
  mdiCarConvertible,
  mdiChartBar,
  mdiChatProcessing,
  mdiCheck,
  mdiCheckCircle,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiCheckboxMarkedOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClockOutline,
  mdiClose,
  mdiCloseCircleOutline,
  mdiCodeBraces,
  mdiCog,
  mdiCogOutline,
  mdiCommentMultiple,
  mdiCommentPlusOutline,
  mdiCommentTextOutline,
  mdiContentCopy,
  mdiCreditCardOutline,
  mdiDiscord,
  mdiDatabaseArrowRightOutline,
  mdiDatabaseExport,
  mdiDelete,
  mdiDeleteOutline,
  mdiDeleteRestore,
  mdiDotsHorizontal,
  mdiDotsVertical,
  mdiDrag,
  mdiDragVertical,
  mdiEarth,
  mdiEmail,
  mdiEmailOutline,
  mdiEmoticonOutline,
  mdiExitRun,
  mdiExitToApp,
  mdiEye,
  mdiEyeOff,
  mdiFaceAgent,
  mdiFlagCheckered,
  mdiFolderSwapOutline,
  mdiFormatAlignCenter,
  mdiFormatAlignLeft,
  mdiFormatAlignRight,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiFormatPilcrow,
  mdiFormatQuoteClose,
  mdiFormatStrikethrough,
  mdiFormatUnderline,
  mdiFormatVerticalAlignCenter,
  mdiFormatVerticalAlignTop,
  mdiForum,
  mdiHelp,
  mdiHelpCircleOutline,
  mdiHistory,
  mdiImage,
  mdiInformationOutline,
  mdiKeyVariant,
  mdiLanguageMarkdownOutline,
  mdiLink,
  mdiLinkVariant,
  mdiLockOutline,
  mdiLockReset,
  mdiMagnify,
  mdiMagnifyMinusOutline,
  mdiMagnifyPlusOutline,
  mdiMapSearch,
  mdiMatrix,
  mdiMenu,
  mdiMenuDown,
  mdiMicrophone,
  mdiMicrosoftTeams,
  mdiMinus,
  mdiOpenInNew,
  mdiPalette,
  mdiPaperclip,
  mdiPencil,
  mdiPin,
  mdiPinOff,
  mdiPinOutline,
  mdiPlus,
  mdiPoll,
  mdiPrinterOutline,
  mdiRadioboxBlank,
  mdiRadioboxMarked,
  mdiRefresh,
  mdiReply,
  mdiRobot,
  mdiRocket,
  mdiSend,
  mdiShieldStar,
  mdiSlack,
  mdiStar,
  mdiTable,
  mdiTableColumnPlusAfter,
  mdiTableColumnPlusBefore,
  mdiTableColumnRemove,
  mdiTableMergeCells,
  mdiTableRemove,
  mdiTableRowPlusAfter,
  mdiTableRowPlusBefore,
  mdiTableRowRemove,
  mdiTagOutline,
  mdiThumbsUpDown,
  mdiTranslate,
  mdiUnfoldMoreHorizontal,
  mdiVideo,
  mdiWeatherNight,
  mdiWebhook,
  mdiWhiteBalanceSunny,
  mdiWindowClose,
  mdiYoutube
} from '@mdi/js';

export default {
  props: {
    color: {
      type: String,
      default: undefined
    },
    dense:  {
      type: Boolean,
      default: false
    },
    disabled:  {
      type: Boolean,
      default: false
    },
    large:  {
      type: Boolean,
      default: false
    },
    left:  {
      type: Boolean,
      default: false
    },
    light:  {
      type: Boolean,
      default: false
    },
    right:  {
      type: Boolean,
      default: false
    },
    size: {
      type: [Number, String],
      default: undefined
    },
    small: {
      type: Boolean,
      default: false
    },
    tag: String,
    XLarge:  {
      type: Boolean,
      default: false
    },
    XSmall: {
      type: Boolean,
      default: false
    },
    name: String
  },

  data() {
    return {
      mdiAccount,
      mdiAccountGroup,
      mdiAccountMultiple,
      mdiAccountMultiplePlus,
      mdiAccountSearch,
      mdiAlarmCheck,
      mdiArchiveOutline,
      mdiArrowDown,
      mdiArrowLeft,
      mdiArrowRight,
      mdiArrowUp,
      mdiArrowUpDown,
      mdiBell,
      mdiBellOff,
      mdiBellOutline,
      mdiBellRingOutline,
      mdiBookOpenPageVariant,
      mdiCalendar,
      mdiCallSplit,
      mdiCamera,
      mdiCancel,
      mdiCarConvertible,
      mdiChartBar,
      mdiChatProcessing,
      mdiCheck,
      mdiCheckCircle,
      mdiCheckboxBlankOutline,
      mdiCheckboxMarked,
      mdiCheckboxMarkedOutline,
      mdiChevronLeft,
      mdiChevronRight,
      mdiClockOutline,
      mdiClose,
      mdiCloseCircleOutline,
      mdiCodeBraces,
      mdiCog,
      mdiCogOutline,
      mdiCommentMultiple,
      mdiCommentPlusOutline,
      mdiCommentTextOutline,
      mdiContentCopy,
      mdiCreditCardOutline,
      mdiDatabaseArrowRightOutline,
      mdiDatabaseExport,
      mdiDiscord,
      mdiDelete,
      mdiDeleteOutline,
      mdiDeleteRestore,
      mdiDotsHorizontal,
      mdiDotsVertical,
      mdiDrag,
      mdiDragVertical,
      mdiEarth,
      mdiEmail,
      mdiEmailOutline,
      mdiEmoticonOutline,
      mdiExitRun,
      mdiExitToApp,
      mdiEye,
      mdiEyeOff,
      mdiFaceAgent,
      mdiFlagCheckered,
      mdiFolderSwapOutline,
      mdiFormatAlignCenter,
      mdiFormatAlignLeft,
      mdiFormatAlignRight,
      mdiFormatHeader1,
      mdiFormatHeader2,
      mdiFormatHeader3,
      mdiFormatBold,
      mdiFormatItalic,
      mdiFormatListBulleted,
      mdiFormatListNumbered,
      mdiFormatPilcrow,
      mdiFormatQuoteClose,
      mdiFormatStrikethrough,
      mdiFormatUnderline,
      mdiFormatVerticalAlignCenter,
      mdiFormatVerticalAlignTop,
      mdiForum,
      mdiHelp,
      mdiHelpCircleOutline,
      mdiHistory,
      mdiImage,
      mdiInformationOutline,
      mdiKeyVariant,
      mdiLanguageMarkdownOutline,
      mdiLink,
      mdiLinkVariant,
      mdiLockOutline,
      mdiLockReset,
      mdiMagnify,
      mdiMagnifyMinusOutline,
      mdiMagnifyPlusOutline,
      mdiMapSearch,
      mdiMatrix,
      mdiMenu,
      mdiMenuDown,
      mdiMicrophone,
      mdiMicrosoftTeams,
      mdiMinus,
      mdiOpenInNew,
      mdiPalette,
      mdiPaperclip,
      mdiPencil,
      mdiPin,
      mdiPinOff,
      mdiPinOutline,
      mdiPlus,
      mdiPoll,
      mdiPrinterOutline,
      mdiRadioboxBlank,
      mdiRadioboxMarked,
      mdiRefresh,
      mdiReply,
      mdiRobot,
      mdiRocket,
      mdiSend,
      mdiShieldStar,
      mdiSlack,
      mdiStar,
      mdiTable,
      mdiTableColumnPlusAfter,
      mdiTableColumnPlusBefore,
      mdiTableColumnRemove,
      mdiTableMergeCells,
      mdiTableRemove,
      mdiTableRowPlusAfter,
      mdiTableRowPlusBefore,
      mdiTableRowRemove,
      mdiTagOutline,
      mdiThumbsUpDown,
      mdiTranslate,
      mdiUnfoldMoreHorizontal,
      mdiVideo,
      mdiWeatherNight,
      mdiWebhook,
      mdiWhiteBalanceSunny,
      mdiWindowClose,
      mdiYoutube
    };
  },

  methods: {
    iconSVG() { return this[camelCase(this.name)] }
  }
};
</script>

<template lang="pug">
v-icon(
  color="color"
  :dense="dense"
  :large="large"
  :left="left"
  :light="light"
  :right="right"
  :small="small"
  :tag="tag"
  :x-large="XLarge"
  :x-small="XSmall"
  :size="size"
) {{ iconSVG() }}
</template>
